<template>
  <div>
    <!-- Hide tags for now -->
    <div class="d-none row bg-white full-banner-tag-filter text-center align-content-center pt-3 mt-1"
         style="overflow-x: scroll; white-space: nowrap; ">
      <div class="col">
        <b-badge href="#" class="filter-tag-pill hand-pointer"
                 :class="{'selected-pill': selection.selectedTag === tag}"
                 variant="light"
                 v-for="tag in setup.tagOptions"
                 :key="tag"
                 @click="selection.selectedTag = tag">
          {{tag |  truncate | humanize}}
        </b-badge>
      </div>
    </div>

    <div class="row bg-white full-banner-filter align-content-center">

      <div class="col-sm text-left text-dark ml-2 pt-2">
        <span class="h3">{{selection.selectedTag | humanize}}</span>
        <!--<span v-if="!isLoading" class="text-muted small ml-1">-->
        <!--({{totalLoaded}} / {{totalAvailable}})-->
        <!--</span>-->
      </div>

      <div class="col-sm float-left text-sm-right float-lg-right">

        <!-- Hide rarity for now -->
        <b-form-select id="scarcity" text="Rarity" variant="primary" class="d-none m-2" size="sm"
                       v-model="selection.selectedScarcity"
                       style="width: 125px;">
          <option :value="null">Filter: Rarity</option>
          <option :value="scarcity.field" v-for="scarcity in setup.scarcityOptions" :key="scarcity.name">
            {{scarcity.name}}
          </option>
        </b-form-select>

        <!--        &lt;!&ndash; Price filter &ndash;&gt;-->
        <!--        <b-form-select id="price-selection" text="By Price" variant="primary" class="m-2" size="sm"-->
        <!--                       v-model="selection.selectPriceFilter"-->
        <!--                       :disabled="selection.disablePriceFilter"-->
        <!--                       style="width: 150px;">-->
        <!--          <option :value="null">Filter: Price</option>-->
        <!--          <option :value="option.value" v-for="option in setup.priceOptions" :key="option.name">-->
        <!--            {{option.name}} (<usd-price :price-in-ether="option.value"></usd-price>)-->
        <!--          </option>-->
        <!--        </b-form-select>-->

        <!--         Artists filter -->
        <!-- Hide artists filter for now -->
        <b-form-select id="artists" text="Artists" variant="secondary" class="d-none m-2" size="sm"
                       v-model="selection.selectedArtist"
                       style="width: 150px;">
          <option :value="null">All Artists</option>
          <option :value="artist" v-for="artist in artists" :key="artist.name">
            {{artist.name}}
          </option>
        </b-form-select>

        <b-form-select id="sort-by" text="Sort by" variant="secondary" class="m-2" size="sm"
                       v-model="selection.selectedOrderBy"
                       :disabled="selection.disableOrderBy"
                       style="width: 150px;">
          <option :value="option" v-for="option in setup.orderByOptions" :key="option.name"
                  :disabled="option.disabled">
            {{option.name}}
          </option>
        </b-form-select>
      </div>
    </div>
  </div>
</template>

<script>

  import {mapGetters} from 'vuex';
  import {sortBy} from 'lodash';
  import UsdPrice from '../generic/USDPrice';
  import * as _ from 'lodash';
  import { BBadge, BFormSelect } from 'bootstrap-vue';

  //const DEFAULT_SORT_ORDER = {name: 'Price: High - Low', field: 'priceInEther', order: 'desc', disabled: false};
  const DEFAULT_SORT_ORDER = {name: 'Auction Start: Low - High', field: 'startDate', order: 'asc', disabled: false}

  const RANDOM_TAG_SELECTION = [
    // tags
    'glitchy',
    'abstract',
    'digital',
    'generative',
    'painting',
    'colour',
    'drawing',
    'illustration',
    'photography',
    'geometric',
    'culture',
    'monochrome',
    // special cases
    'all',
    'recent'
  ];

  const selectRandomTag = () => {
    return RANDOM_TAG_SELECTION[Math.floor(Math.random() * RANDOM_TAG_SELECTION.length)];
  };

  export default {
    name: 'gallery-filter-bar',
    components: {
      UsdPrice,
      ...{ BBadge, BFormSelect }
    },
    props: ['totalAvailable', 'totalLoaded', 'isLoading'],
    data() {
      return {
        selection: {
          //selectedTag: selectRandomTag('all'),
          selectedTag:'all',
          selectPriceFilter: null,
          selectedScarcity: null,
          selectedArtist: null,
          selectedOrderBy: DEFAULT_SORT_ORDER,
          disablePriceFilter: false,
          disableOrderBy: false
        },
        setup: {
          orderByOptions: [
            DEFAULT_SORT_ORDER,
            // {name: 'Price: Low - High', field: 'priceInEther', order: 'asc', disabled: false},
            // {name: 'Scarcity: Low - High', field: 'totalAvailable', order: 'asc', disabled: false},
            // {name: 'Scarcity: High - Low', field: 'totalAvailable', order: 'desc', disabled: false},
            {name: 'Auction Start: Low - High', field: 'startDate', order: 'asc', disabled: false},
            {name: 'Auction Start: High - Low', field: 'startDate', order: 'desc', disabled: false},
          ],
          scarcityOptions: [
            {name: 'Ultra Rare (1 of 1)', field: 'ultrarare'},
            {name: 'Rare (1 - 10)', field: 'rare'},
            {name: 'Common (10+)', field: 'common'},
          ],
          priceOptions: [
            {name: '1 +', value: 1.1},
            {name: 'Up to 1', value: 1},
            {name: 'Up to 0.75', value: 0.75},
            {name: 'Up to 0.50', value: 0.5},
            {name: 'Up to 0.25', value: 0.25},
            {name: 'Up to 0.1', value: 0.1},
          ],
          tagOptions: [
            'all',
            'recent',
            'digital',
            'illustration',
            'colour',
            'generative',
            'character',
            'abstract',
            'animation',
            'painting',
            'photography',
            'drawing',
            'pattern',
            'geometric',
            'glitchy',
            'art',
            'sci-fi',
            'culture',
            '3d render',
            'psychedelic',
            'technology',
            'fractal',
            'nostalgia',
            'crypto',
            'series',
            'monochrome',
          ]
        },
      };
    },
    methods: {
      buildFilters(selection) {
        const filters = {};

        if (selection.selectedScarcity) {
          filters['scarcity_filter'] = selection.selectedScarcity;
        }
        if (selection.selectedTag && (selection.selectedTag !== 'all' && selection.selectedTag !== 'recent')) {
          filters['tag_filter'] = selection.selectedTag;
        }

        if (selection.selectPriceFilter) {
          filters['priceInEther_filter'] = selection.selectPriceFilter;
        }

        if (selection.selectedArtist) {
          filters['artist_filter'] = selection.selectedArtist.ethAddress[0];
        }

        if (selection.selectedOrderBy) {
          filters.orderBy = selection.selectedOrderBy.field;
          filters.order = selection.selectedOrderBy.order;

          if (selection.selectedTag && selection.selectedTag === 'recent') {
            filters.orderBy = 'edition';
          }
        }
        return filters;
      }
    },
    computed: {
      ...mapGetters([
        'liveArtists',
        'findArtistsForAddress',
      ]),
      artists() {
        return sortBy(this.liveArtists, 'name');
      }
    },
    created() {
      if (this.$route.query.tag) {
        this.selection.selectedTag = this.$route.query.tag;

        // When a tag is found which is not know, set tag as 2nd item in tag list
        if (!_.includes(this.setup.tagOptions, this.$route.query.tag)) {
          const firstTag = this.setup.tagOptions[0];
          const newTag = this.$route.query.tag;
          const remainingTags = this.setup.tagOptions.splice(1, this.setup.tagOptions.length);

          this.setup.tagOptions = [
            firstTag, newTag, ...remainingTags
          ];
        }
      }
      if (this.$route.query.artist) {
        this.selection.selectedArtist = this.findArtistsForAddress(this.$route.query.artist);

        // If artists selected with no secondary tag filter, all the all tag by default
        if (!this.$route.query.tag) {
          this.selection.selectedTag = 'all';
        }
      }

      if (this.$route.query.rarity) {
        const validRarity = _.find(this.setup.scarcityOptions, {field: this.$route.query.rarity});
        if (validRarity) {
          this.selection.selectedScarcity = validRarity.field;
        }
      }

      this.$emit('filters-changed', this.buildFilters(this.selection));
    },
    watch: {
      'selection.selectedScarcity': function (newVal) {
        this.$ga.event('gallery', 'filters-applied-scarcity', newVal);

        let query = Object.assign({}, this.$route.query, {rarity: newVal});
        if (!newVal) {
          delete query['rarity'];
        }
        this.$router.push({query: query});

        this.$emit('filters-changed', this.buildFilters(this.selection));
      },
      'selection.selectedArtist': function (newVal) {
        this.$ga.event('gallery', 'filters-applied-artist', newVal);

        let query;
        if (newVal) {
          query = Object.assign({}, this.$route.query, {artist: newVal.ethAddress[0]});
        } else {
          query = Object.assign({}, this.$route.query);
          delete query['artist'];
        }
        this.$router.push({query: query});

        this.$emit('filters-changed', this.buildFilters(this.selection));
      },
      'selection.selectedTag': function (newVal) {
        this.$ga.event('gallery', 'filters-applied-tags', newVal);

        // disable other sort if sorting by recent
        if (newVal === 'recent') {
          this.selection.disableOrderBy = true;
          this.selection.selectedOrderBy = DEFAULT_SORT_ORDER;
        } else {
          this.selection.disableOrderBy = false;
        }

        let query = Object.assign({}, this.$route.query, {tag: newVal});
        if (!newVal) {
          delete query['tag'];
        }
        this.$router.push({query: query});

        this.$emit('filters-changed', this.buildFilters(this.selection));
      },
      'selection.selectPriceFilter': function (newVal) {
        this.$ga.event('gallery', 'filters-applied-price', newVal);

        if (this.selection.selectPriceFilter) {
          // When using price we can use this as well
          _.forEach(this.setup.orderByOptions, (option) => {
            if (option.field === 'totalAvailable') {
              option.disabled = true;
            }
          });
        } else {
          // When switching, enable the filter
          _.forEach(this.setup.orderByOptions, (option) => {
            if (option.field === 'totalAvailable') {
              option.disabled = false;
            }
          });
        }

        this.$emit('filters-changed', this.buildFilters(this.selection));
      },
      'selection.selectedOrderBy': function (newVal) {
        this.$ga.event('gallery', 'filters-applied-sort-order', newVal);

        // We cant use two different equality checks so we disable price filter when sorting by scarcity
        if (this.selection.selectedOrderBy && this.selection.selectedOrderBy.field === 'totalAvailable') {
          this.selection.disablePriceFilter = true;
        } else {
          this.selection.disablePriceFilter = false;
        }

        this.$ga.event('gallery', 'filters-applied', this.selection);
        this.$emit('filters-changed', this.buildFilters(this.selection));
      }
    },
  };
</script>

<style scoped lang="scss">
  @import '../../ko-colours.scss';

  // Remove scrollbars
  div::-webkit-scrollbar {
    width: 0 !important
  }

  div {
    -ms-overflow-style: none;
  }

  .full-banner-filter {
    min-height: 50px;
  }

  .full-banner-tag-filter {
    min-height: 75px;
  }

  .filter-tag-pill {
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: #dee2e6;
    background-color: #f8f9fa;
    font-size: 0.85rem;
    color: $secondary;
  }

  .filter-tag-pill:hover {
    background-color: #f8f9fa;
    border-color: $secondary;
    border-style: solid;
    border-width: 2px;
  }

  .selected-pill:hover {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    background-color: #F8F8FF !important;;
    border-color: $primary !important;
    color: $primary;
    font-size: 0.85rem;
  }

  .selected-pill {
    padding: 10px;
    margin: 10px;
    border-style: solid;
    border-width: 2px;
    background-color: #F8F8FF !important;
    border-color: $primary;
    color: $primary !important;;
    font-size: 0.85rem;
  }

</style>
