<template>
  <div class='container'>

    <gallery-filter-bar
      v-on:filters-changed="filtersChanged"
      :total-loaded="editions.length"
      :total-available="totalAvailable"
      :is-loading="isLoading"
      class="editions-wrap">
    </gallery-filter-bar>

    <div class="mt-4">

      <div class="row editions-wrap">
        <div class="card-deck">
          <div class="col-12 col-sm-4 mb-5"
               v-for="edition in editions" :key="edition.edition"
               v-if="edition.active">
            <gallery-card :edition="edition" :edition-number="edition.edition"></gallery-card>
          </div>
        </div>
      </div>

      <infinite-loading @infinite="showMore" :identifier="infiniteId">
        <div slot="spinner">
          <loading-spinner></loading-spinner>
        </div>
        <div slot="no-more mt-2 mb-5">
          <span v-if="!isLoading">Everything loaded</span>
        </div>
      </infinite-loading>

    </div>
  </div>
</template>
<script>

  import * as _ from 'lodash';
  import {mapState} from 'vuex';
  import {PAGES} from '../store/loadingPageState';
  import GalleryCard from '../components/cards/GalleryCard';
  import InfiniteLoading from 'vue-infinite-loading';
  import GalleryFilterBar from '../components/gallery/GalleryFilterBar';
  import LoadingSpinner from '../components/generic/LoadingSpinner';

  export default {
    name: 'galleryKODAV2',
    components: {
      LoadingSpinner,
      GalleryFilterBar,
      GalleryCard,
      InfiniteLoading
    },
    data() {
      return {
        PAGES,
        limit: 18,
        offset: 0,
        resultsSize: 0,
        totalAvailable: 0,
        isLoading: false,
        editions: [],
        filters: {},
        infiniteId: +new Date()
      };
    },
    computed: {
      ...mapState([
        'editionLookupService',
      ]),
    },
    methods: {
      filtersChanged(filters) {
        // console.log('filters', filters);
        this.limit = 18;
        this.offset = 0;
        this.resultsSize = 0;
        this.totalAvailable = 0;
        this.infiniteId += 1;
        this.editions = [];
        this.filters = filters;
        // this.showMore();
      },
      goToArtist(artistAccount) {
        this.$router.push({name: 'artist', params: {artistAccount}});
      },
      showMore($state) {
        this.isLoading = true;

        const payload = {
          ...this.filters,
          limit: this.limit,
          offset: this.offset,
        };

        this.editionLookupService.getGalleryEditions(payload)
          .then((results) => {
            if (results.success) {
              const {data, totalAvailable, params, resultsSize} = results;
              const {limit, offset} = params;

              this.limit = limit;
              this.offset = offset + limit;
              this.totalAvailable = totalAvailable;
              this.resultsSize = resultsSize;

              _.forEach(data, (result) => {
                this.editions.push(result);
              });
            }
          })
          .then(() => {

            if (this.resultsSize > 0) {
              $state.loaded();
            } else {
              $state.complete();
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    }
  };
</script>


<style scoped lang="scss">
  @import '../ko-colours.scss';
  @import '../ko-card.scss';

  .full-banner {
    p {
      margin-bottom: 0;
    }
  }

  .sub-filter {
    cursor: pointer;
    padding-left: 3rem;
    padding-right: 3rem;
    color: $body-bg;
  }

  /* mobile only */
  @media screen and (max-width: 767px) {
    .full-banner {
      font-size: 1.5rem;
    }

    .sub-filter {
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }
  }

  @import '../ko-card.scss';
</style>
